import { useAtom } from "jotai";
import * as React from "react";

import { metaAtom } from "@/store";

const ChatBotInjector = () => {
  const [meta] = useAtom(metaAtom);

  React.useEffect(() => {
    if (meta.behindAuth || meta.disableChatBot) {
      if (!(window as any).widgetObj) {
        return;
      }

      const widgetObj = Promise.resolve((window as any).widgetObj);
      widgetObj.then((obj) => {
        obj.destroyWidget();
      });
    } else {
      if ((window as any).widgetObj) {
        return;
      }

      const widgetScriptExists = document.getElementById(
        "sw-supchat-widget-script"
      );

      if (widgetScriptExists) {
        widgetScriptExists.remove();
      }

      const widgetScript = document.createElement("script");
      widgetScript.id = "sw-supchat-widget-script";
      widgetScript.src =
        "https://supchat.skat.supwizapp.com/widget/main.js?config_id=13";
      widgetScript.onload = () => {
        // @ts-ignore
        (window as any).widgetObj = configureWidget();
      };
      document.body.append(widgetScript);
    }
  }, [meta]);

  return <></>;
};

export default ChatBotInjector;
